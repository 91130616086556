<template>
  <div class="flex flex-col justify-between items-center">
    <div>
      <div class="flex justify-center">
        <div class="mt-s8 text-center headline-large">
          {{$t('drops.order_confirmed')}}
        </div>
      </div>
      
      <div class="flex justify-center mt-s24">
        <span class="bg-background-positive-text p-s32 rounded-full">
          <IconCheck class="w-12 h-12"/>
        </span>
      </div>

      <div class="success-receipt">
        <p class="font-bold mt-s64">
          {{ playerName }} - {{ dropName }}
        </p>

        <div class="flex justify-between mt-s12 font-semibold">
          <p>
            {{$t('drops.amount_purchased')}}
          </p>

          <p>
            ${{numberFormat(usdAmount)}}
          </p>
        </div>

        <div class="flex justify-between mt-s12 font-semibold">
          <p>
            {{$t('drops.total_asset_value')}}
          </p>

          <p>
            ${{numberFormat(drop.asset_value_usd)}}
          </p>
        </div>
      </div>
    </div>

    <ButtonV2
      @onClick="handleClose"
      class="mt-s24"
      :label="$t('done')"
      size="medium"
      testId="btn-done"
      wide
    />
  </div>
</template>

<script>
import IconCheck from '@/assets/icons/check.svg';
import ButtonV2 from '@/stories/misc/ButtonV2';
import { mapActions } from 'vuex';

export default {
  name: 'DropConfirmed',
  components: {
    IconCheck,
    ButtonV2,
  },

  props: {
    drop: Object,
    dropEventData: Object,
    dropName: String,
    playerName: String,
    usdAmount: [String, Number],
  },

  async mounted() {
    this.setModalProps({
      customClose: this.handleClose,
    });

    await this.$store.dispatch('events/track', {
      event: 'ASSET_DROP_CHECKOUT_RECEIPT_VIEWED',
      variables: {
        ...this.dropEventData,
        max_amount: this.usdAmount,
      },
    });
  },

  methods: {
    ...mapActions('ui', ['setModalProps']),

    async handleClose() {
      this.hideModal();
    },
  },
};
</script>

<style scoped>
  .success-receipt {
    @apply w-full body-text-x-space border border-border bg-background-primary rounded-16 p-s16;
    margin-top: -62px;
  }
</style>
